<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <v-row class="auth-row ma-0" justify="center" align="center">
                <v-col cols="12">
                    <div class="login-area py-15 px-3 text-center">
                        <p class="text-2xl d-none d-sm-block font-weight-semibold text--primary mb-2">
                            Stream Stickers Dashboard Login
                        </p>
                        <p class="text-xl d-xs-block d-sm-none font-weight-semibold text--primary mb-2">
                            Stream Stickers Dashboard Login
                        </p>
                        <p class="mb-2">Please sign in with your Twitch Account</p>

                        <v-btn color="primary" depressed type="button" class="mt-6 py-7 px-7" @click="doLogin">
                            Login with Twitch <img class="ml-2" src="@/assets/images/Twitch.png" />
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig';
import { getApiEndpoint } from '../utils/config';

export default {
    setup() {
        const doLogin = () => {
            if (!window.location.host.startsWith('localhost')) {
                window.location.href = getApiEndpoint() + '/auth/twitch/start';
            } else {
                window.location.href = getApiEndpoint() + '/auth/twitch/start?isLocal=true';
            }
        };

        return {
            doLogin,

            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.auth-row {
    max-height: 100vh;
    overflow-x: none !important;
    background: url('../assets/images/backgrounds/Discord_Invite-BG.png');
    background-size: cover;
    background-position: center bottom;
}

.login-area {
    width: 450px;
    background: var(--v-cardBg-base);
    opacity: 0.95;
    border-radius: 6px;
    margin: auto;
}

@media screen and (max-width: 480px) {
    .login-area {
        max-width: 95%;
    }
}
</style>
